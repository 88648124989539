<template>
  <app-module-view>
    <template slot="body">
      <section class="m-b-10">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-right m-r-5">
              <button
                v-if="isSaveButtonVisible"
                type="button"
                class="btn btn-success"
                @click="save"
              >
                <i class="fa fa-save "></i> {{ $t('feUser.save') }}
              </button>
              <app-button-delete v-if="isDeleteButtonVisible" @deleteRecord="deleteRecord" />
              <app-button-close @click="$router.go(-1)"></app-button-close>
            </div>
          </div>
        </div>
      </section>
      <div class="card">
        <div class="card-body">
          <section class="content form-horizontal">
            <div class="box box-info">
              <div class="box-body">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="col-lg-12">
                      <input type="hidden" v-model="feUserArticle.id">
                      <app-input
                        v-model="feUserArticle.articleId"
                        @blur="$v.feUserArticle.articleId.$touch()"
                        :error="$v.feUserArticle.articleId.$error"
                        id="feUserArticle_articleId"
                        :label="$t('feUser.article_id')"
                      >
                      </app-input>
                    </div>
                    <div class="col-lg-12" :class="{'has-danger': $v.feUserArticle.feUserExpanded.$error}">
                      <app-input v-if="feUserArticle.id"
                                 v-model="feUserArticle.feUserExpanded.email"
                                 id="feUserArticle_feUserExpanded"
                                 :label="$t('feUser.user_email')"
                                 readonly
                      >
                      </app-input>
                      <label v-if="!feUserArticle.id">{{ $t('feUser.user_email') }}</label>
                      <app-multi-select
                        v-if="!feUserArticle.id"
                        v-model="feUserArticle.feUserExpanded"
                        :options="feUsers"
                        :close-on-select="true"
                        :hide-selected="true"
                        :preserve-search="true"
                        placeholder="Select one"
                        label="email"
                        track-by="id"
                        :preselect-first="true"
                        :loading="isLoading"
                        :internal-search="false"
                        :options-limit="300"
                        :limit="10"
                        :max-height="600"
                        :show-no-results="false"
                        @search-change="findFeUser"
                        selectLabel=""
                        id="feUserArticle_feUserExpanded"
                      >
                      </app-multi-select>
                    </div>
                  </div>
                  <div class="col-lg-3">
                  </div>
                  <div class="col-lg-3">
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../../ModuleView'
import FeUserArticleModel from '../../../model/feUser/FeUserArticle'
import Input from '../../form/inputs/Input'
import ButtonDelete from '../../shared/ButtonDelete'
import ButtonClose from '../../shared/ButtonClose'
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import NotifyService from '../../../services/NotifyService'
import MultiSelect from 'vue-multiselect'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'FeUserNew',
  data () {
    return {
      isLoading: false,
      feUserArticle: this._.cloneDeep(FeUserArticleModel),
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.FRONTEND_USER_ARTICLE_PERMISSIONS
    }
  },
  computed: {
    currentUser () {
      return this.$store.getters['user/currentUser']
    },
    isDeleteButtonVisible () {
      return this.feUserArticle?.id && this.hasPermission(this.requiredPermissions.deleteButton)
    },
    isSaveButtonVisible () {
      return this.hasPermission(this.requiredPermissions.saveButton)
    },
    feUsers () {
      return this.$store.getters['feUser/list']
    }
  },
  validations: {
    feUserArticle: {
      feUserExpanded: {
        required
      },
      articleId: {
        required,
        maxLength: maxLength(36),
        minLength: minLength(36)
      }
    }
  },
  components: {
    appModuleView: ModuleView,
    appInput: Input,
    appButtonDelete: ButtonDelete,
    appButtonClose: ButtonClose,
    appMultiSelect: MultiSelect
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    prepareFeUserArticleRequest (feUserArticle) {
      if (feUserArticle.feUserExpanded && feUserArticle.feUserExpanded.id) {
        feUserArticle.feUser = feUserArticle.feUserExpanded.id
      }
      delete feUserArticle.feUserExpanded
      return feUserArticle
    },
    async save () {
      this.$v.$touch()

      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('feUser.error.required_fields'))
        return
      }
      // Create user address
      this.$store.dispatch(
        'feUserArticle/create',
        this.prepareFeUserArticleRequest(this.feUserArticle)
      )
        .then(() => {
          this.feUserArticle = this.$store.getters['feUserArticle/detail']
          if (this.$store.getters['feUserArticle/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('feUser.message.updated_record'))
            this.$router.push('/feUserArticle/' + this.feUserArticle.id + '/edit')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['feUserArticle/error'])
          }
        })
        .catch(error => console.log(error))
    },
    deleteRecord () {
      this.$store.dispatch('feUserArticle/deleteRecord', this.feUserArticle)
        .then(() => {
          if (this.$store.getters['feUserArticle/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('feUser.message.deleted_record'))
            this.$router.push('/feUserArticle')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['feUserArticle/error'])
          }
        })
        .catch(error => console.log(error))
    },
    findFeUser (query) {
      this.$store.dispatch('feUser/fetch', query)
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
div.has-danger .multiselect__tags {
  border-color: red;
}
</style>
